import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-robots-pic-mobile',
  templateUrl: './robots-pic-mobile.component.html',
  styleUrls: ['./robots-pic-mobile.component.scss']
})
export class RobotsPicMobileComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
