import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-label-with-underline',
  templateUrl: './label-with-underline.component.html',
  styleUrls: ['./label-with-underline.component.scss']
})
export class LabelWithUnderlineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
