import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { tap, switchMap, map, catchError } from 'rxjs/operators';
import { SubmitContactForm, SUBMIT_CONTACT_FORM, SubmitContactFormSuccess, SubmitContactFormError } from '../store/lobby.actions';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class LobbyEffects {
  constructor(private actions$: Actions, private http: HttpClient) { }

  @Effect()
  submitContactForm$: Observable<Action> = this.actions$
    .ofType(SUBMIT_CONTACT_FORM).pipe(
      map(action => action['payload']),
      switchMap(payload =>
        this.http.post('https://contact-us.brillianetor.com/SendMail', payload).pipe(
          map(res => { return new SubmitContactFormSuccess() }),
          catchError(error => {
            return of(new SubmitContactFormError(error));
          })
        ))
    );

}
