import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ValidateEmail } from '../../store/user.actions';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss', "../../../css/main.css"]
})
export class ValidateEmailComponent implements OnInit {

  success$: Observable<number>;

  constructor(private activatedRoute: ActivatedRoute, private store: Store<{ user: UserState }>) { }

  ngOnInit() {
    debugger;
    this.success$ = this.store.select(state => state.user.loadingState);    
    let token = this.activatedRoute.snapshot.queryParamMap.get('token');    
    this.store.dispatch(new ValidateEmail(token));
  }

}

interface UserState {
  loadingState: number;
}
