import { Action } from '@ngrx/store'
import { ContactForm } from '../models/contact-form';

export const VALIDATE_EMAIL = '[USER] VALIDATE EMAIL'
export const VALIDATE_EMAIL_SUCCESS = '[USER] VALIDATE EMAIL SUCCESS'
export const VALIDATE_EMAIL_ERROR = '[USER] VALIDATE EMAIL ERROR'

// Section 3
export class ValidateEmail implements Action {
    readonly type = VALIDATE_EMAIL

    constructor(public payload: string) { }
}

export class ValidateEmailSuccess implements Action {
    readonly type = VALIDATE_EMAIL_SUCCESS
    constructor() { }
}

export class ValidateEmailError implements Action {
    readonly type = VALIDATE_EMAIL_ERROR

    constructor(public payload: any) { }
}

// Section 4
export type Actions = ValidateEmail | ValidateEmailSuccess | ValidateEmailError;