import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  visible:boolean = false;

  constructor() { }

  ButtonClick(){
    this.visible = !this.visible;
  }

  ngOnInit() {
    const video = document.getElementById('myVideo');
    video.oncanplay  = function() {
      const cont = document.getElementsByClassName('vsc-controller');
      if ( cont.length > 0 ) {
        cont[0].remove();
      }
    };
  }

}
