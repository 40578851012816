import { Action } from '@ngrx/store'
import { ContactForm } from '../models/contact-form';

export const SUBMIT_CONTACT_FORM = '[LOBBY] SUBMIT CONTACT'
export const SUBMIT_CONTACT_FORM_SUCCESS = '[LOBBY] SUBMIT CONTACT SUCCESS'
export const SUBMIT_CONTACT_FORM_ERROR = '[LOBBY] SUBMIT CONTACT ERROR'

// Section 3
export class SubmitContactForm implements Action {
    readonly type = SUBMIT_CONTACT_FORM

    constructor(public payload: ContactForm) { }
}

export class SubmitContactFormSuccess implements Action {
    readonly type = SUBMIT_CONTACT_FORM_SUCCESS
    constructor() { }
}

export class SubmitContactFormError implements Action {
    readonly type = SUBMIT_CONTACT_FORM_ERROR

    constructor(public payload: any) { }
}

// Section 4
export type Actions = SubmitContactForm | SubmitContactFormSuccess | SubmitContactFormError;