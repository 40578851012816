import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LabelWithUnderlineComponent } from "./label-with-underline.component";

@NgModule({
  imports: [CommonModule],
  declarations: [LabelWithUnderlineComponent],
  exports: [LabelWithUnderlineComponent]
})
export class LabelWithUnderlineModule {}
