import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NguCarouselConfig } from "@ngu/carousel";

@Component({
  selector: "app-gaming",
  templateUrl: "./gaming.component.html",
  styleUrls: ["./gaming.component.scss"]
})
export class GamingComponent implements OnInit {
  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 2, lg: 3, all: 0 },
    slide: 1,
    speed: 250,
    point: {
      visible: true
    },
    load: 2,
    velocity: 0,
    touch: true,
    loop: true,
    interval: { timing: 1500 },
    easing: "cubic-bezier(0, 0, 0.2, 1)"
  };

  shootingVideos = [
    {
      header: "Shooting Game - Before brillianetor (bots are done as a swarm):",
      url: "https://www.youtube.com/embed/qarUb_XPRRY",
      safeUrl: null
    },
    {
      header:
        "Shooting Game - After Brillianetor, bots  decide together on goals and the teamwork strategies",
      url: "https://www.youtube.com/embed/B_nRCDKVcZc",
      safeUrl: null
    },
    {
      header:
        "Shooting Game - After Brillianetor, bots  decide together on goals and the teamwork strategies",
      url: "https://www.youtube.com/embed/B_nRCDKVcZc",
      safeUrl: null
    }
  ];

  carRaceVideos = [
    {
      header: "BEFORE Brillianetor: Bots play ball - without Social AI",
      url: "https://www.youtube.com/embed/xLaxAK7YuLE",
      safeUrl: null
    },
    {
      header: "BEFORE Brillianetor: Bots play ball - without Social AI",
      url: "https://www.youtube.com/embed/1MB2DwpnR_Q",
      safeUrl: null
    },
    {
      header: "BEFORE Brillianetor: Bots play ball - without Social AI",
      url: "https://www.youtube.com/embed/W1ivOfoXsRQ",
      safeUrl: null
    }
  ];

  kidsVideos = [
    {
      header: "BEFORE Brillianetor: Bots play ball - without Social AI",
      url: "https://www.youtube.com/embed/EXCqBEzOjrY",
      safeUrl: null
    },
    {
      header: "AFTER Brillianetor: Bots (with Social AI) play ball together ",
      url: "https://www.youtube.com/embed/hA0DUtktfkg",
      safeUrl: null
    },
    {
      header: "BEFORE Brillianetor: Bots without Social AI Dance Alone",
      url: "https://www.youtube.com/embed/k-EEDVBr2Z0",
      safeUrl: null
    },
    {
      header: "AFTER Brillianetor: Bots (with Social AI) Dance together",
      url: "https://www.youtube.com/embed/Rfc_cICzBX4",
      safeUrl: null
    },
    {
      header: "BEFORE Brillianetor: Mutual dependence between the bots",
      url: "https://www.youtube.com/embed/H56j17LX84U",
      safeUrl: null
    },
    {
      header:
        "AFTER Brillianetor: Mutual dependence between the bots with Brillianetor",
      url: "https://www.youtube.com/embed/YBhFsjUIJFw",
      safeUrl: null
    },
    {
      header: "AFTER Brillianetor: Bots assist each other",
      url: "https://www.youtube.com/embed/uY3ACkFLLCs",
      safeUrl: null
    },
    {
      header: "BEFORE Brillianetor: No ability to apply force together",
      url: "https://www.youtube.com/embed/ux6Lj4EzpIA",
      safeUrl: null
    },
    {
      header: "AFTER  Brillianetor: Bots apply force together with Social AI ",
      url: "https://www.youtube.com/embed/9RD5xKVp65U",
      safeUrl: null
    }
  ];

  constructor(private dom: DomSanitizer) {}

  ngOnInit() {
    for (let item of this.kidsVideos) {
      item.safeUrl = this.dom.bypassSecurityTrustResourceUrl(item.url);
    }

    for (let item of this.carRaceVideos) {
      item.safeUrl = this.dom.bypassSecurityTrustResourceUrl(item.url);
    }

    for (let item of this.shootingVideos) {
      item.safeUrl = this.dom.bypassSecurityTrustResourceUrl(item.url);
    }
  }  
}
