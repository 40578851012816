import { Component, OnInit } from "@angular/core";
import { NguCarouselConfig } from "@ngu/carousel";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-robotics",
  templateUrl: "./robotics.component.html",
  styleUrls: ["./robotics.component.scss"]
})
export class RoboticsComponent implements OnInit {
  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 2, lg: 3, all: 0 },
    slide: 1,
    speed: 250,
    point: {
      visible: true
    },
    load: 2,
    velocity: 0,
    touch: true,
    loop: true,
    interval: { timing: 1500 },
    easing: "cubic-bezier(0, 0, 0.2, 1)"
  };

  warehouseVideos = [
    {
      header: "Shooting Game - Before brillianetor (bots are done as a swarm):",
      url: "https://www.youtube.com/embed/oA1fXs9fp98",
      safeUrl: null,
      description: "Ideal scenario without problems"
    },
    {
      header:
        "Shooting Game - After Brillianetor, bots  decide together on goals and the teamwork strategies",
      url: "https://www.youtube.com/embed/NDsBx-QFfdw ",
      safeUrl: null,
      description: "Reaction to a change in the number of robots"
    },
    {
      header:
        "Shooting Game - After Brillianetor, bots  decide together on goals and the teamwork strategies",
      url: "https://www.youtube.com/embed/XH9ruGhv0so",
      safeUrl: null,
      description: "Reaction to a change in the speed of group members"
    },
    {
      header:
        "Shooting Game - After Brillianetor, bots  decide together on goals and the teamwork strategies",
      url: "https://www.youtube.com/embed/G9HahEador4",
      safeUrl: null,
      description: "Reaction to a robot malfunction"
    }
  ];
  constructor(private dom: DomSanitizer) {}

  ngOnInit() {
    let smallestFactor = this.smallestFactor(this.warehouseVideos.length);
    this.carouselTile.slide = smallestFactor;
    this.carouselTile.grid.lg = smallestFactor;
    // for (let key in this.carouselTile.grid) {
    //   debugger;
    //   if (key !== "all") {
    //     this.carouselTile.grid[key] = Math.floor(12 / this.carouselTile.slide);
    //   }
    // }

    for (let item of this.warehouseVideos) {
      item.safeUrl = this.dom.bypassSecurityTrustResourceUrl(item.url);
    }
  }

  smallestFactor(num) {
    var smallest = 1;
    for (let i = num; i >= 2; i--) {
      if (num % i === 0) {
        smallest = i;
      }
    }
    return smallest;
  }
}
