import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { tap, switchMap, map, catchError } from 'rxjs/operators';
import { SubmitContactForm, SUBMIT_CONTACT_FORM, SubmitContactFormSuccess, SubmitContactFormError } from '../store/lobby.actions';
import { HttpClient } from '@angular/common/http';
import { ValidateEmailSuccess, ValidateEmailError, VALIDATE_EMAIL } from '../store/user.actions';


@Injectable()
export class UserEffects {

  constructor(private actions$: Actions, private http: HttpClient) { }

  @Effect()
  submitContactForm$: Observable<Action> = this.actions$
    .ofType(VALIDATE_EMAIL).pipe(
      map(action => action['payload']),
      switchMap(payload =>
        this.http.get(`http://users.brillianetor.com/api/Users/ValidateEmail/?token=${payload}`).pipe(
          map(res => { return new ValidateEmailSuccess() }),
          catchError(error => {            
            return of(new ValidateEmailError(error));
          })
        ))
    );

}
