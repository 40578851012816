import { Action } from '@ngrx/store'
import * as UserActions from './user.actions'
import { FormGroup, Validators, FormControl } from '@angular/forms';


// Section 1
const initialState = {
    loadingState: -1
}

// Section 2
export function userReducer(state = initialState, action: UserActions.Actions) {

    // Section 3
    switch (action.type) {
        case UserActions.VALIDATE_EMAIL:
            return { ...state };
        case UserActions.VALIDATE_EMAIL_SUCCESS:
        {
               debugger;
                return { ...state, loadingState: 1 };
            }
        case UserActions.VALIDATE_EMAIL_ERROR:
            {
                return { ...state, loadingState: 2 };
            }
        default:
            return state;
    }
}
