/*

 this component canceled by yehonatan because the new design was diffrent in the main screen.
 i copied the whole logic as is to the new contact component in new design folder

 it is steel in use in the direct contact us link

*/



import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { ContactForm } from "src/app/models/contact-form";
import { SubmitContactForm } from "src/app/store/lobby.actions";
import { LobbyState } from "src/app/store/lobby.reducer";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"]
})
export class ContactComponent implements OnInit {
  contactForm$: Observable<FormGroup>;
  contact: ContactForm;
  submitSuccess$: Observable<boolean>;
  submitSuccess: boolean;
  isSent:boolean=false // this var dont listent to the store- it is only for ui


  //@Output('submitted') submitted = new EventEmitter<ContactForm>();

  constructor(private store: Store<{ lobby: LobbyState }>) {
    this.submitSuccess$ = this.store.select(state => state.lobby.submitSuccess);
    this.contactForm$ = this.store.select(state => state.lobby.contactForm);
  }
  ngOnInit() {}

  submit() {
    debugger
    this.store.dispatch(new SubmitContactForm(this.contact));
    this.isSent = true;
    console.log();
    setTimeout(() => { this.isSent = false; }, 4000);
  }
}
