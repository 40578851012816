import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-robots-pic',
  templateUrl: './robots-pic.component.html',
  styleUrls: ['./robots-pic.component.scss']
})
export class RobotsPicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
