import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule, routerReducer } from "@ngrx/router-store";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";

import { AppComponent } from "./app.component";
import { lobbyReducer } from "./store/lobby.reducer";
import { LobbyEffects } from "./effects/lobby.effects.service";
import { userReducer } from "./store/user.reducer";
import { UserEffects } from "./effects/user.effects.service";
import { TopNavModule } from "./top-nav/top-nav.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  NgbDropdownModule,
  NgbCarouselModule
} from "@ng-bootstrap/ng-bootstrap";
import { NguCarouselModule } from "@ngu/carousel";
import { PlatformComponent } from "./components/platform/platform.component";
import { SolutionsComponent } from "./components/solutions/solutions.component";
import { AboutComponent } from "./components/new-design/about/about.component";
import { ProductComponent } from "./components/new-design/product/product.component";
import { ValidateEmailComponent } from "./components/validate-email/validate-email.component";
import { RoboticsComponent } from "./components/solutions/robotics/robotics.component";
import { SimulatorsComponent } from "./components/solutions/simulators/simulators.component";
import { GamingComponent } from "./components/solutions/gaming/gaming.component";
import { LabelWithUnderlineModule } from "./label-with-underline/label-with-underline.module";
import { OurTechnologyComponent } from "./components/our-technology/our-technology.component";
import { DownloadPageComponent } from "./components/download-page/download-page.component";
import { HomeComponent } from "./components/new-design/home/home.component";
import { UseCasesComponent } from "./components/new-design/use-cases/use-cases.component";
import { NewContactComponent } from "./components/new-design/contact/contact.component";
import {RobotsPicComponent} from "./components/new-design/robots-pic/robots-pic.component";
import { RobotsPicMobileComponent } from "./components/new-design/robots-pic-mobile/robots-pic-mobile.component";
import { ContactComponent } from "./components/contact/contact.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UseCasesComponent,
    PlatformComponent,
    SolutionsComponent,
    RoboticsComponent,
    SimulatorsComponent,
    GamingComponent,
    OurTechnologyComponent,
    AboutComponent,
    ContactComponent,
    NewContactComponent,
    RobotsPicComponent,
    RobotsPicMobileComponent,
    ProductComponent,
    ValidateEmailComponent,
    DownloadPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ScrollToModule.forRoot(),
    StoreModule.forRoot({
      lobby: lobbyReducer,
      user: userReducer,
      router: routerReducer
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([LobbyEffects, UserEffects]),
    TopNavModule,
    LabelWithUnderlineModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NguCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
