import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  hasReadMoreOpen1: boolean = false;
  hasReadMoreOpen2: boolean = false;
  hasReadMoreOpen3: boolean = false;
  hasReadMoreOpen4: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  readMore(index) {
    switch (index) {
      case 1:
        this.hasReadMoreOpen1 = true;
        break;
      case 2:
        this.hasReadMoreOpen2 = true;
        break;
      case 3:
        this.hasReadMoreOpen3 = true;
        break;
      case 4:
        this.hasReadMoreOpen4 = true;
        break;
      default:
        break;
    }
  }
}
