import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-use-cases',
  templateUrl: './use-cases.component.html',
  styleUrls: ['./use-cases.component.scss']
})
export class UseCasesComponent implements OnInit {
  visible: boolean = false;
  constructor() { }

  ButtonClick() {
    this.visible = !this.visible;
  }

  ngOnInit() {
  }

}
