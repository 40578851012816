import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbCarousel } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";

@Component({
  selector: "app-our-technology",
  templateUrl: "./our-technology.component.html",
  styleUrls: ["./our-technology.component.scss"]
})
export class OurTechnologyComponent implements OnInit {
  activeId: string = "ngb-slide-1";
  constructor(private activated: ActivatedRoute, router: Router) {}

  ngOnInit() {    
    // this.activated.paramMap.pipe(take(1)).subscribe(params => {
    //   let redirector = params.get("r");
    //   this.activeId = redirector === "b" ? "ngb-slide-1" : "ngb-slide-0";
    // });
  }
}
