import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {SubmitContactForm} from 'src/app/store/lobby.actions';
import {LobbyState} from 'src/app/store/lobby.reducer';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class NewContactComponent implements OnInit {
  submitSuccess: boolean;
  submitSuccessOb$: Observable<boolean>;
  contactForm: FormGroup;
  submitted: boolean;
  constructor(private store: Store<{ lobby: LobbyState }>,
              private formBuilder: FormBuilder) {
    this.submitSuccessOb$ = this.store.select(state => state.lobby.submitSuccess);
  }

  get f() {
    return this.contactForm.controls;
  }

  ngOnInit() {
    this.submitted = false;
    this.submitSuccess = false;
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      message: ['', Validators.required]
    });
  }

  submit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }

    this.store.dispatch(new SubmitContactForm(
      this.contactForm.value
    ));
    this.submitSuccessOb$.subscribe(res => {
      if (res === true) {
        this.submitSuccess = true;
      } else {
        alert('ERROR: Please try again later.');
      }
    });
  }
}
