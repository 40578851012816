import { Action } from "@ngrx/store";
import * as LobbyActions from "./lobby.actions";
import { FormGroup, Validators, FormControl } from "@angular/forms";

export interface LobbyState {
  contactForm: FormGroup;
  submitSuccess: boolean;
}

// Section 1
const initialState: LobbyState = {
  submitSuccess: false,
  contactForm: new FormGroup({
    name: new FormControl(""),
    email: new FormControl("", [Validators.email, Validators.required]),
    company: new FormControl(""),
    subject: new FormControl(""),
    message: new FormControl("")
  })
};

// Section 2
export function lobbyReducer(
  state = initialState,
  action: LobbyActions.Actions
) {
  // Section 3
  switch (action.type) {
    case LobbyActions.SUBMIT_CONTACT_FORM:
      return { ...state };
    case LobbyActions.SUBMIT_CONTACT_FORM_SUCCESS:
      return { ...state, submitSuccess: true };
    case LobbyActions.SUBMIT_CONTACT_FORM_ERROR:
      return { ...state };
    default:
      return state;
  }
}
