import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AboutComponent } from "./components/new-design/about/about.component";
import { PlatformComponent } from "./components/platform/platform.component";
import { ProductComponent } from "./components/new-design/product/product.component";
import { ValidateEmailComponent } from "./components/validate-email/validate-email.component";
import { SolutionsComponent } from "./components/solutions/solutions.component";
import { RoboticsComponent } from "./components/solutions/robotics/robotics.component";
import { SimulatorsComponent } from "./components/solutions/simulators/simulators.component";
import { GamingComponent } from "./components/solutions/gaming/gaming.component";
import { OurTechnologyComponent } from "./components/our-technology/our-technology.component";
import { DownloadPageComponent } from "./components/download-page/download-page.component";
import { HomeComponent } from "./components/new-design/home/home.component";
import { UseCasesComponent } from "./components/new-design/use-cases/use-cases.component";
import { NewContactComponent } from "./components/new-design/contact/contact.component";
import {RobotsPicComponent} from "./components/new-design/robots-pic/robots-pic.component";
import { ContactComponent } from "./components/contact/contact.component";
const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: HomeComponent
  },
  {
    path: "use-cases",
    component: UseCasesComponent
  },
  {
    path: "platform",
    component: PlatformComponent
  },
  {
    path: "contact",
    component: NewContactComponent
  },
  {
    path: "validate-email",
    component: ValidateEmailComponent
  },
  {
    path: "product",
    component: ProductComponent
  },
  {
    path: "robotics",
    component: RoboticsComponent
  },
  {
    path: "about",
    component: AboutComponent
  },
  {
    path: "solutions",
    component: RoboticsComponent,
    children: [
      { path: "robotics", component: RoboticsComponent },
      { path: "simulators", component: SimulatorsComponent },
      { path: "gaming", component: GamingComponent }
    ]
  },

  { path: "technology", component: OurTechnologyComponent },
  { path: "download", component: DownloadPageComponent },
  {
    path: "**",
    redirectTo: ""
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
